@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600&display=swap');

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Plus Jakarta Sans';
  background-color: #101a26;
}

body > #root > div {
  height: 100vh;
}

.ant-drawer-content{
  overflow: hidden !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.drag-item-container{
  margin-bottom: 10px;
}